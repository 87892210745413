import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { auth } from './firebase'; // Import the initialized auth instance
import ProtectedRoute from './ProtectedRoute';
import { HelmetProvider } from 'react-helmet-async';
// import AuthPopup from './AuthPopup';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import DeleteInstructionsPage from './DeleteInstructionsPage';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import BetaSignupPopup from './BetaSignupPopup';
import MBetaSignupPopup from './MBetaSignupPopup';

// import MHomePage from './MHomePage';
// import MHomePageNL from './MHomePageNL';
// import HomePage from './HomePage';
// import HomePageNL from './HomePageNL';
// import VisionPage from './VisionPage';
// import VisionPageNL from './VisionPageNL';

// import PreregistrationPage from './PreregistrationPage';
// import MPreregistrationPage from './MPreregistrationPage';


// Import your components/pages




ReactGA.initialize('G-MTCEMGHMQY', {
  debug: true,
});



const MHomePage = lazy(() => import('./MHomePage'));
const HomePage = lazy(() => import('./HomePage'));
const HomePageNL = lazy(() => import('./HomePageNL'));
const MHomePageNL = lazy(() => import('./MHomePageNL'));
const VisionPage = lazy(() => import('./VisionPage'));
const VisionPageNL = lazy(() => import('./VisionPageNL'));
const PreregistrationPage = lazy(() => import('./PreregistrationPage'));
const MPreregistrationPage = lazy(() => import('./MPreregistrationPage'));
const BlogPage = lazy(() => import('./Blog'));
const BlogOverviewPage = lazy(() => import('./BlogOverview'));
const BlogOverviewPageNL = lazy(() => import('./BlogOverviewNL'));
const MBlogOverviewPage = lazy(() => import('./MBlogOverview'));
const MBlogPage = lazy(() => import('./MBlog'));
const MBlogOverviewPageNL = lazy(() => import('./MBlogOverviewNL'));


const AppContent = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [pixelInitialized, setPixelInitialized] = useState(false);


  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  useEffect(() => {
    // Initialize the Facebook Pixel only once when the component mounts
    ReactPixel.init('905067834290197');
    ReactPixel.pageView(); // Track the initial page view
    setPixelInitialized(true);
  }, []);

  useEffect(() => {
    if (pixelInitialized) {
      ReactPixel.pageView(); // This tracks the page view
      ReactPixel.track('PageView', { page_path: location.pathname });
    }
  }, [location, pixelInitialized])

  const showPopup = () => {
    // Track the event
    ReactGA.event({
      category: 'Button Click',
      action: 'Clicked on Login',
      label: 'Login Popup'
    });
    ReactPixel.trackCustom('LoginClick');

    // Show the popup
    setIsPopupVisible(true);
  };

  const hidePopup = () => {
    setIsPopupVisible(false);
  }


  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user); // Set user when logged in
      } else {
        setUser(null); // Clear user when not logged in
      }
      setLoading(false);
    });

    return () => unsubscribe(); // Clean up the subscription
  }, []);

  useEffect(() => {
    const redirectStarted = sessionStorage.getItem('redirectStarted');
    if (redirectStarted) {
      setIsPopupVisible(true);
    }
  })

  return (!loading && (
    <div className="app-container">
      {isDesktop?<BetaSignupPopup isVisible={isPopupVisible} onClose={hidePopup}/>:<MBetaSignupPopup isVisible={isPopupVisible} onClose={hidePopup}/>}
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {/* Default redirect to /en */}
          <Route path="/" element={isDesktop ? <HomePage onLoginClick={showPopup} /> : <MHomePage onLoginClick={showPopup} />} />

          {/* English Routes */}
          <Route path="/en" element={isDesktop ? <HomePage onLoginClick={showPopup} /> : <MHomePage onLoginClick={showPopup} />} />
          <Route path="/en/blog" element={isDesktop ? <BlogOverviewPage onLoginClick={showPopup} /> : <MBlogOverviewPage onLoginClick={showPopup} />} />

          {/* Blog 1 */}
          <Route path="/en/jointly-make-friends-guide-belgium" element={isDesktop ?
            <BlogPage
              onLoginClick={showPopup}
              h1={"Making Friends in Belgium: Jointly's"}
              h2={"guide to building new connections"}
              blogimg={"/assets/meeting.jpg"}
              blogimgalt={"Making friends in Antwerp"}
              blogmd={'/blogs/blog1.md'} /> :
            <MBlogPage
              onLoginClick={showPopup}
              h1={"Making Friends in Belgium: Jointly's"}
              h2={"guide to building new connections"}
              blogimg={"/assets/meeting.jpg"}
              blogimgalt={"Making friends in Antwerp"}
              blogmd={'/blogs/blog1.md'} />} />

          {/* blog 2 */}
          <Route path="/en/jointly-vision" element={isDesktop ?
            <BlogPage
              onLoginClick={showPopup}
              h1={"How Jointly wants to make meeting"}
              h2={"like-minded people easier in a digital world"}
              blogimg={"/assets/vision.webp"}
              blogimgalt={"The plan and vision of the Jointly meet up app"}
              blogmd={'/blogs/blog2.md'} /> :
            <MBlogPage
              onLoginClick={showPopup}
              h1={"How Jointly wants to make meeting"}
              h2={"like-minded people easier in a digital world"}
              blogimg={"/assets/vision.webp"}
              blogimgalt={"The plan and vision of the Jointly meet up app"}
              blogmd={'/blogs/blog2.md'} />} />

          <Route path="/en/ourvision" element={<VisionPage onLoginClick={showPopup} />} />
          <Route path="/en/preregistration" element={isDesktop ?
            <ProtectedRoute user={user}>
              <PreregistrationPage />
            </ProtectedRoute> :
            <ProtectedRoute user={user}>
              <MPreregistrationPage />
            </ProtectedRoute>
          } />

          {/* Dutch Routes */}
          <Route path="/nl" element={isDesktop ? <HomePageNL onLoginClick={showPopup} /> : <MHomePageNL onLoginClick={showPopup} />} />

          {/* blog */}
          <Route path="/nl/blog" element={isDesktop ? <BlogOverviewPageNL onLoginClick={showPopup} /> : <MBlogOverviewPageNL onLoginClick={showPopup} />} />

          {/* Blog 1 */}
          <Route path="/nl/jointly-make-friends-guide-belgium" element={isDesktop ?
            <BlogPage
              onLoginClick={showPopup}
              h1={"Vrienden maken in Belgie: Jointly's"}
              h2={"tips om nieuwe connecties te bouwen"}
              blogimg={"/assets/meeting.jpg"}
              blogimgalt={"Vrienden maken in Antwerpen"}
              blogmd={'/blogs/blog1NL.md'} /> :
            <MBlogPage
              onLoginClick={showPopup}
              h1={"Vrienden maken in Belgie: Jointly's"}
              h2={"tips om nieuwe connecties te bouwen"}
              blogimg={"/assets/meeting.jpg"}
              blogimgalt={"Vrienden maken in Antwerpen"}
              blogmd={'/blogs/blog1NL.md'} />} />

          {/* blog 2 */}
          <Route path="/nl/jointly-vision" element={isDesktop ?
            <BlogPage
              onLoginClick={showPopup}
              h1={"Hoe Jointly mensen leren kennen"}
              h2={"makkelijk wil maken in deze digitale wereld"}
              blogimg={"/assets/vision.webp"}
              blogimgalt={"The plan and vision of the Jointly meet up app"}
              blogmd={'/blogs/blog2NL.md'} /> :
            <MBlogPage
              onLoginClick={showPopup}
              h1={"Hoe Jointly mensen leren kennen"}
              h2={"makkelijk wil maken in deze digitale wereld"}
              blogimg={"/assets/vision.webp"}
              blogimgalt={"Het plan en de visie van de Jointly meet up app"}
              blogmd={'/blogs/blog2NL.md'} />} />

          <Route path="/nl/ourvision" element={<VisionPageNL onLoginClick={showPopup} />} />
          <Route path="/nl/preregistration" element={isDesktop ?
            <ProtectedRoute user={user}>
              <PreregistrationPage />
            </ProtectedRoute> :
            <ProtectedRoute user={user}>
              <MPreregistrationPage />
            </ProtectedRoute>
          } />

          {/* <Route path="/privacypolicy" element={<PrivacyPolicyPage/>} /> */}
          <Route path="/deleteinstructions" element={<DeleteInstructionsPage />} />
        </Routes>
      </Suspense>
    </div>)
  );
}



const App = () => {
  // Redirect base URL ("/") to "/en"
  // const RedirectToDefaultLanguage = () => {
  //   const location = useLocation();
  //   // Check if the path is just "/" and redirect to "/en" as default
  //   if (location.pathname === "/") {
  //     return <Navigate to="/en" replace />;
  //   }
  //   return null; // No redirection needed for other paths
  // };

  return (
    <HelmetProvider>
      <Router>
        <AppContent /> {/* Moved the Router content here */}
      </Router>
    </HelmetProvider>
  );
};

export default App;
